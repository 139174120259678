<template>
   <div style="width:100%">
		<v-card block :loading="isLoading">
			<v-card-title>
				Управление РУМЦ <v-chip label small class="ml-2">Всего: {{ total }}</v-chip>
			</v-card-title>
			<v-card-text>
				<v-text-field label="Поиск события" filled v-model="searchRumc" clearable></v-text-field>

				<v-data-table :headers="headers" :items="rumcs" :items-per-page="countRows" class="elevation-1" hide-default-footer>
					<template v-slot:item.count_access="{ item }">
						<v-btn small elevation="0" color="purple lighten-5" :to="{
							name: 'Users',
							query: {
								atype: 'Rumc',
								aid: item.id
							}
						}">{{ item.count_user_access }} / {{ item.count_access }}</v-btn>
					</template>
					<template v-slot:item.setting="{ item }">
						<v-btn small icon @click="openEditDialog( item.id )" :loading="btnLoadingEdit == item.id" color="teal" class="mr-2">
							<v-icon>mdi-circle-edit-outline</v-icon>
						</v-btn>
						<v-btn small icon @click="deleteOrg( item.id )" color="red">
							<v-icon>mdi-delete-outline</v-icon>
						</v-btn>
					</template>
				</v-data-table>
				<div class="pt-4">
					<v-row justify="space-between">
						<v-col cols=2 align-self="center">
							<v-select :items="itemsCountRows" v-model="countRows" label="Кол-во записей" hide-details solo></v-select>
						</v-col>
						<v-spacer></v-spacer>
						<v-col align-self="center" class="pag-end">
							<v-pagination v-model="page" :length="totalPage" :total-visible="7"></v-pagination>
						</v-col>
					</v-row>
				</div>
			</v-card-text>
		</v-card>

		<v-dialog v-model="dialogEditOrg" max-width="1200px">
			<v-card>
				<v-toolbar color="primary" dark>
					<v-toolbar-title>Редактирование данных о РУМЦ</v-toolbar-title>
				</v-toolbar>
				<v-card-text>
					<div class="mt-5">

						<v-textarea
							filled
							label="Наименование РУМЦ"
							rows="2"
							auto-grow
							v-model="dataEditOrg.title"
						></v-textarea>

						<v-text-field
							label="Адрес РУМЦ"
							outlined
							v-model="dataEditOrg.address"
						></v-text-field>

						<v-text-field
							label="Телефон РУМЦ"
							outlined
							v-model="dataEditOrg.phone"
							v-mask="'# (###) ###-##-##'"
						></v-text-field>

						<v-select
							:items="regions"
							v-model="dataEditOrg.region_id"
							outlined
							item-text="title"
							item-value="id"
							label="Субъект организации"
						></v-select>

						<v-select
							:items="ministries"
							v-model="dataEditOrg.ministry_id"
							outlined
							item-text="title"
							item-value="id"
							label="Министерство"
						></v-select>

						<v-text-field
							label="Руководитель"
							outlined
							v-model="dataEditOrg.head"
						></v-text-field>
						
						<v-text-field
							label="Эл. почта руководитель"
							outlined
							v-model="dataEditOrg.email_head"
						></v-text-field>

						<v-text-field
							label="Телефон руководителя"
							outlined
							v-model="dataEditOrg.phone_head"
							v-mask="'# (###) ###-##-##'"
						></v-text-field>

						<v-text-field
							label="Краткое наименование"
							outlined
							v-model="dataEditOrg.title_short"
						></v-text-field>

						<v-text-field
							label="Эл. почта РУМЦ"
							outlined
							v-model="dataEditOrg.email"
						></v-text-field>

						<v-textarea
							filled
							label="Контактное лицо"
							rows="2"
							auto-grow
							v-model="dataEditOrg.contact"
						></v-textarea>

						<v-text-field
							label="URL раздела информации о РУМЦ"
							outlined
							v-model="dataEditOrg.url"
						></v-text-field>

						<v-text-field
							label="ID владельца"
							outlined
							v-model="dataEditOrg.owner_id"
						></v-text-field>

						<v-text-field
							label="Ведомственная принадлежность (для страницы с картой)"
							outlined
							v-model="dataEditOrg.attachment"
						></v-text-field>

						<v-textarea
							filled
							label="Реквизиты мираполис"
							rows="2"
							auto-grow
							v-model="dataEditOrg.mirapolis_info"
						></v-textarea>

						<v-switch
							v-model="dataEditOrg.state"
							hide-details
							label="РУМЦ активен"
						></v-switch>
					</div>
				</v-card-text>
				<v-card-actions>
					<v-btn color="primary darken-1" text @click="dialogEditOrg = false" >
						Закрыть
					</v-btn>
					<v-spacer></v-spacer>
					<v-btn color="teal darken-1" text @click="saveEdit()">
						Сохранить
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
   </div>
</template>

<script>
export default {
	data () {
		return {
			isLoading : false,

			headers: [
				{ text: 'ID', align: 'start', sortable: true, value: 'id', },
				{ text: 'Наименование', value: 'title' },
				{ text: 'Пользователи / Права', value: 'count_access', align: 'center', sortable: false },
				{ text: 'Управление', value: 'setting', align: 'right', sortable: false },
			],

			itemsCountRows : [
				12, 20, 50, 100, 500
			],

			rumcs: [],
			regions: [],
			ministries: [],

			total: 0,
			page: 1,
			totalPage: 1,
			countRows: 12,

			btnLoadingEdit: 0,
			dialogEditOrg: false,
			dataEditOrg: {},
			searchRumc: ''
		}
	},
	watch: {
		'page': function(){
			if ( !this.isLoading )
				this.getRumcs()
		},
		'countRows': function(val) {
			if ( !this.isLoading ) {
				this.getRumcs()
				localStorage.setItem("countRows", parseInt(val))
			}
		},
		'searchRumc': function() {
			this.getRumcs()
		}
	},
	methods: {
		// ? Сохранить информацию о РУМЦ
		saveEdit() {
			this.$confirm.q(`Вы подтверждаете изменение РУМЦ #${this.dataEditOrg.id}?`, () => {
				this.$http.post(`rumcs/${this.dataEditOrg.id}`,{
					form: this.dataEditOrg
				}).then((response) => {
					if ( response.data.status ) {
						this.$toast.open('Информация о РУМЦ отредактирован');
						this.dialogEditOrg = false
						this.getRumcs()
					}
				});
			}, () => {
				this.$toast.warning("Действие отменено пользователем");
			})
		},

		// ? Получить информацию об организации для редактирования 
		openEditDialog( id ){
			this.btnLoadingEdit = id
			this.$http.get(`rumcs/${id}`).then((response) => {
				this.dialogEditOrg = true
				this.dataEditOrg = response.data.item
				console.log(response.data.item)
				
				setTimeout(() => {
					this.btnLoadingEdit = false
				},300)
			});
		},

		deleteOrg( id ) {
			this.$toast.warning(`РУМЦ ${id} не может быть удален. Действие заблокировано!`);
		},

		getRumcs(){
			this.isLoading = true
			this.$http.get("rumcs", { params: {
				count: this.countRows,
				page: this.page,
				search: this.searchRumc ? this.searchRumc : this.$route.query.search
			} }).then((response) => {
				this.regions = response.data.regions
				this.ministries = response.data.ministries

				this.rumcs = response.data.items
				this.total = parseInt(response.data.options.total)
				this.page = parseInt(response.data.options.page)
				this.totalPage = parseInt(response.data.options.totalPage)

				setTimeout(() => {
					this.isLoading = false
				}, 200)
			});
		}
	},
	created(){
		const countRows = localStorage.getItem("countRows")
		if ( countRows && parseInt(countRows) > 0 ) {
			this.countRows = parseInt(countRows)
		}
		this.getRumcs()
	}
}
</script>

<style>
.link-email { color:#1A237E; text-decoration: none !important; border-bottom: 1px dashed #1A237E;}
.pag-end .v-pagination { justify-content: right !important; }
</style>